import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.event.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.event.fields.title'),
  },
  {
    name: 'headerImage',
    label: i18n('entities.event.fields.headerImage'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'startDate',
    label: i18n('entities.event.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endDate',
    label: i18n('entities.event.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'allDay',
    label: i18n('entities.event.fields.allDay'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'dayOfWeek',
    label: i18n('entities.event.fields.dayOfWeek'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'endRecur',
    label: i18n('entities.event.fields.endRecur'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'description',
    label: i18n('entities.event.fields.description'),
  },
  {
    name: 'price',
    label: i18n('entities.event.fields.price'),
  },
  {
    name: 'notes',
    label: i18n('entities.event.fields.notes'),
  },
  {
    name: 'location',
    label: i18n('entities.event.fields.location'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'host',
    label: i18n('entities.event.fields.host'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.event.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.event.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
