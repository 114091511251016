import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin],
         allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      restaurantImport: {
        id: 'restaurantImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      restaurantCreate: {
        id: 'restaurantCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.restaurantLogo,
        ],
      },
      restaurantEdit: {
        id: 'restaurantEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.restaurantLogo,
        ],
      },
      restaurantDestroy: {
        id: 'restaurantDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.restaurantLogo,
        ],
      },
      restaurantRead: {
        id: 'restaurantRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      restaurantAutocomplete: {
        id: 'restaurantAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      menuImport: {
        id: 'menuImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      menuCreate: {
        id: 'menuCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.menuMenuFile,
        ],
      },
      menuEdit: {
        id: 'menuEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.menuMenuFile,
        ],
      },
      menuDestroy: {
        id: 'menuDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.menuMenuFile,
        ],
      },
      menuRead: {
        id: 'menuRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      menuAutocomplete: {
        id: 'menuAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      menuItemImport: {
        id: 'menuItemImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      menuItemCreate: {
        id: 'menuItemCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      menuItemEdit: {
        id: 'menuItemEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      menuItemDestroy: {
        id: 'menuItemDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      menuItemRead: {
        id: 'menuItemRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      menuItemAutocomplete: {
        id: 'menuItemAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      menuSectionImport: {
        id: 'menuSectionImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      menuSectionCreate: {
        id: 'menuSectionCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      menuSectionEdit: {
        id: 'menuSectionEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      menuSectionDestroy: {
        id: 'menuSectionDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      menuSectionRead: {
        id: 'menuSectionRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      menuSectionAutocomplete: {
        id: 'menuSectionAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      roomImport: {
        id: 'roomImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      roomCreate: {
        id: 'roomCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      roomEdit: {
        id: 'roomEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      roomDestroy: {
        id: 'roomDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      roomRead: {
        id: 'roomRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      roomAutocomplete: {
        id: 'roomAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      placeImport: {
        id: 'placeImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      placeCreate: {
        id: 'placeCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      placeEdit: {
        id: 'placeEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      placeDestroy: {
        id: 'placeDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      placeRead: {
        id: 'placeRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      placeAutocomplete: {
        id: 'placeAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      promotionImport: {
        id: 'promotionImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      promotionCreate: {
        id: 'promotionCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.promotionAttachment,
          storage.promotionCoverImage,
        ],
      },
      promotionEdit: {
        id: 'promotionEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.promotionAttachment,
          storage.promotionCoverImage,
        ],
      },
      promotionDestroy: {
        id: 'promotionDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.promotionAttachment,
          storage.promotionCoverImage,
        ],
      },
      promotionRead: {
        id: 'promotionRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      promotionAutocomplete: {
        id: 'promotionAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      sitemapImport: {
        id: 'sitemapImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      sitemapCreate: {
        id: 'sitemapCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.sitemapHeaderImage,
        ],
      },
      sitemapEdit: {
        id: 'sitemapEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.sitemapHeaderImage,
        ],
      },
      sitemapDestroy: {
        id: 'sitemapDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.sitemapHeaderImage,
        ],
      },
      sitemapRead: {
        id: 'sitemapRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      sitemapAutocomplete: {
        id: 'sitemapAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      sitemapCategoriesImport: {
        id: 'sitemapCategoriesImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      sitemapCategoriesCreate: {
        id: 'sitemapCategoriesCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      sitemapCategoriesEdit: {
        id: 'sitemapCategoriesEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      sitemapCategoriesDestroy: {
        id: 'sitemapCategoriesDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      sitemapCategoriesRead: {
        id: 'sitemapCategoriesRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      sitemapCategoriesAutocomplete: {
        id: 'sitemapCategoriesAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      tenantProfileImport: {
        id: 'tenantProfileImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tenantProfileCreate: {
        id: 'tenantProfileCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.tenantProfileWallpaper,
        ],
      },
      tenantProfileEdit: {
        id: 'tenantProfileEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.tenantProfileWallpaper,
        ],
      },
      tenantProfileDestroy: {
        id: 'tenantProfileDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.tenantProfileWallpaper,
        ],
      },
      tenantProfileRead: {
        id: 'tenantProfileRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tenantProfileAutocomplete: {
        id: 'tenantProfileAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      onlineOrderImport: {
        id: 'onlineOrderImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      onlineOrderCreate: {
        id: 'onlineOrderCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.onlineOrderImage,
        ],
      },
      onlineOrderEdit: {
        id: 'onlineOrderEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.onlineOrderImage,
        ],
      },
      onlineOrderDestroy: {
        id: 'onlineOrderDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.onlineOrderImage,
        ],
      },
      onlineOrderRead: {
        id: 'onlineOrderRead',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      onlineOrderAutocomplete: {
        id: 'onlineOrderAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
    
    eventImport: {
      id: 'eventImport',
      allowedRoles: [roles.admin],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
    },
    eventCreate: {
      id: 'eventCreate',
      allowedRoles: [roles.admin],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
      allowedStorage: [
        storage.eventHeaderImage,
      ],
    },
    eventEdit: {
      id: 'eventEdit',
      allowedRoles: [roles.admin],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
      allowedStorage: [
        storage.eventHeaderImage,
      ],
    },
    eventDestroy: {
      id: 'eventDestroy',
      allowedRoles: [roles.admin],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
      allowedStorage: [
        storage.eventHeaderImage,
      ],
    },
    eventRead: {
      id: 'eventRead',
      allowedRoles: [roles.admin, roles.custom],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
    },
    eventAutocomplete: {
      id: 'eventAutocomplete',
      allowedRoles: [roles.admin, roles.custom],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
    },

    hostImport: {
      id: 'hostImport',
      allowedRoles: [roles.admin],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
    },
    hostCreate: {
      id: 'hostCreate',
      allowedRoles: [roles.admin],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
      allowedStorage: [
        storage.hostHeadshot,
      ],
    },
    hostEdit: {
      id: 'hostEdit',
      allowedRoles: [roles.admin],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
      allowedStorage: [
        storage.hostHeadshot,
      ],
    },
    hostDestroy: {
      id: 'hostDestroy',
      allowedRoles: [roles.admin],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
      allowedStorage: [
        storage.hostHeadshot,
      ],
    },
    hostRead: {
      id: 'hostRead',
      allowedRoles: [roles.admin, roles.custom],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
    },
    hostAutocomplete: {
      id: 'hostAutocomplete',
      allowedRoles: [roles.admin, roles.custom],
      allowedPlans: [plans.free, plans.growth, plans.enterprise],
    },
  };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
